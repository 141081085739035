import { Suspense, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';

import Button from 'common/components/atoms/Button/Button';
import { usePageExit } from 'utils/hooks/usePageExit';
import { lazyWithRetry } from 'utils/lazyWithRetry';

import { Spinner } from '@omnipresentgroup/design-system';

import { ReactComponent as LockedIcon } from './images/lock.svg';
import { ReactComponent as UnLockedIcon } from './images/unLocked.svg';
import { Container, ToolBar, Wrapper } from './MarkDownEditor.styles';

const Editor = lazyWithRetry(() => import('rich-markdown-editor'));

interface MarkDownEditorProps {
  content: string;
  saveMarkDownContent: (data: string) => void;
  placeholder?: string;
}

export const MarkDownEditor = ({
  content,
  saveMarkDownContent,
  placeholder = '',
}: MarkDownEditorProps) => {
  const [contentLocked, updateContentLock] = useState(Boolean(content));
  const [checkListContent, updateCheckListContent] = useState(content);

  const { showExitPrompt, setShowExitPrompt } = usePageExit(false);

  useEffect(() => {
    return () => {
      setShowExitPrompt(false);
    };
  }, [setShowExitPrompt]);

  return (
    <Wrapper>
      <Prompt
        when={showExitPrompt}
        message="Changes that you made would not be saved"
      />
      <ToolBar>
        <Button
          data-testid="lock-unlock-button"
          icon={contentLocked ? <LockedIcon /> : <UnLockedIcon />}
          onClick={() => {
            updateContentLock(!contentLocked);
            if (!contentLocked) {
              checkListContent !== content &&
                saveMarkDownContent(checkListContent);
              setShowExitPrompt(false);
            }
          }}
        >
          {contentLocked ? 'Locked' : 'Re-lock'}
        </Button>
      </ToolBar>
      <Container data-testid="editor-container">
        {/* Not ideal but this is to test the typing behaviour in the tests. Would be very happy if we can find a better way to do this! */}
        <input
          data-testid="editor-input"
          style={{ display: 'none' }}
          onChange={(e) => updateCheckListContent(e.target.value)}
        />
        <Suspense fallback={<Spinner size="40" />}>
          <Editor
            id="editor-content"
            defaultValue={content}
            readOnly={contentLocked}
            readOnlyWriteCheckboxes
            placeholder={placeholder}
            onChange={(valChange) => {
              const val = valChange();
              if (contentLocked) {
                saveMarkDownContent(val);
              } else {
                setShowExitPrompt(true);
                updateCheckListContent(val);
              }
            }}
          />
        </Suspense>
      </Container>
    </Wrapper>
  );
};
