import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { subject } from '@casl/ability';
import { PageContent } from 'app/App.styles';
import appHistory from 'app/appHistory';
import { useAppAbility } from 'app/store/ability';
import { getLoadingSelector } from 'app/store/app.selectors';
import avatarPlaceholderPicture from 'assets/img/avatar-placeholder.png';
import { getEmployeeDataExportRequest } from 'common/api/employees.api';
import FloatingActionButton from 'common/components/atoms/FloatingActionButton/FloatingActionButton';
import { ConfirmationModal } from 'common/components/molecules/ConfirmationModal';
import { OnboardingStatus } from 'common/components/molecules/OnboardingStatus/OnboardingStatus';
import PageHeader from 'common/components/molecules/PageHeader/PageHeader';
import DocumentsTab from 'common/components/organisms/DocumentsTab/DocumentsTab';
import { Tabs } from 'common/components/organisms/Tabs/Tabs';
import { TimeOffContent } from 'common/components/organisms/TimeOffContent/TimeOffContent';
import { getEmployeeExpensesAction } from 'omniplatform/employee/store/actions/expenses.actions';
import { getEmployeeExpensesSelector } from 'omniplatform/employee/store/selectors/expenses.selector';
import { getEmployeeExpensesAction as getEmployeeExpensesActionAsManager } from 'omniplatform/manager/store/actions/managersEmployeeExpenses.actions';
import { getManagersEmployeesExpensesSelector } from 'omniplatform/manager/store/selectors/managersEmployeeExpenses.selector';
import {
  ADD_EMPLOYMENT_PAGE,
  EMPLOYEES_PATH,
  getEmployeeListPath,
} from 'paths';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { getCountryFlagByCountryName } from 'utils/countries';
import { isTimeOffIntegrationEnabled } from 'utils/featureFlags';
import { useSelectUserRoles } from 'utils/hooks';
import {
  useCompanyQuery,
  useDepartmentQuery,
  useEmployeeTimeOffQuery,
  useSingleSeatQuery,
} from 'utils/queries';

import { Viewport } from '@omnipresentgroup/design-system';

import Loading from '../../components/atoms/Loading/Loading';
import {
  clearDocumentsAction,
  deleteDocumentAction,
  getDocumentsAction,
} from '../../store/actions/documents.actions';
import {
  clearSelectedEmployeeAction,
  getEmployeeAction,
  updateDepartmentAction,
  updateEmployeeEmploymentFAQAction,
} from '../../store/actions/employee.actions';
import { getDocumentsSelector } from '../../store/selectors/document.selectors';
import { getSelectedEmployeeSelector } from '../../store/selectors/employees.selector';
import {
  StyledEmployeeDetailsPage,
  StyledProfilePicture,
} from './EmployeeDetailsPage.styles';
import EmployeeExpensesTab from './Tabs/EmployeeExpensesTab';
import { EmployeeInfoTab } from './Tabs/EmployeeInfoTab/EmployeeInfoTab';
import { EmploymentFAQTab } from './Tabs/EmploymentFAQTab';
import { EmploymentHistory } from './Tabs/EmploymentHistoryTab/EmploymentHistory';

const PENDING_CONTRACT_INFO = 'success-pending-review';
const MULTIDOC_PENDING_CONTRACT_INFO = 'multidoc-success-pending-review';

const EmployeeDetailsPage = ({ addDocumentPath = '' }) => {
  const dispatch = useDispatch();
  const { employeeId } = useParams();
  const location = useLocation();
  const [currentHash, setCurrentHash] = useState(location.hash || null);
  const initialInfoModal = Object.keys(queryString.parse(location.search))[0];
  const [visibleModal, setVisibleModal] = useState(initialInfoModal);
  const [buttonConfig, setButtonConfig] = useState({});
  const [isOnboardingInProgress, setIsOnboardingInProgress] = useState(true);
  const ability = useAppAbility();
  const { isAdmin, isLSP, isCSAdmin, main: userRole } = useSelectUserRoles();

  const employee = useSelector(getSelectedEmployeeSelector);
  const loading = useSelector(getLoadingSelector);
  const employeeDocuments = useSelector(getDocumentsSelector);
  const genericEmployeeExpenses = useSelector(getEmployeeExpensesSelector);
  const managerEmployeeExpenses = useSelector(
    getManagersEmployeesExpensesSelector,
  );

  const employeeTimeOffQuery = useEmployeeTimeOffQuery(employeeId, {
    staleTime: Infinity,
  });

  const departmentQuery = useDepartmentQuery();

  const canEditEmployeeDetails = !isLSP;
  const canAddDocument = ability.can(
    'documents:create',
    subject('Documents', { ownerId: employee.userId }),
  );

  const canDeleteDocument = (id) =>
    ability.can('documents:delete', subject('Documents', { id }));

  const canDownloadDocument = (id) =>
    ability.can('documents:download', subject('Documents', { id }));

  const employeeExpenses = {
    admin: genericEmployeeExpenses,
    lsp: genericEmployeeExpenses,
    manager: managerEmployeeExpenses,
  }[userRole];

  const CONFIRMATION_MODAL_TITLE = "We're finalising your contract now";
  const confirmationModalMessage = useRef('');

  useEffect(() => {
    if (visibleModal === PENDING_CONTRACT_INFO) {
      confirmationModalMessage.current =
        'Our team will add additional information to your contract and you will be notified when it is ready for your review and approval.';
    } else if (visibleModal === MULTIDOC_PENDING_CONTRACT_INFO) {
      confirmationModalMessage.current =
        'Before this contract can be generated, our team needs to collect additional documentation from you in order to generate the contract. \
      They will be in touch shortly.';
    }
  }, [visibleModal]);

  useEffect(() => {
    dispatch(getEmployeeAction(employeeId));
    return () => {
      dispatch(clearSelectedEmployeeAction());
    };
  }, [dispatch, employeeId]);

  useEffect(() => {
    const getUserSpecificEmployeeExpenses = {
      admin: (args) => dispatch(getEmployeeExpensesAction(args)),
      lsp: (args) => dispatch(getEmployeeExpensesAction(args)),
      manager: (args) => dispatch(getEmployeeExpensesActionAsManager(args)),
    }[userRole];

    if (!getEmployeeExpensesAction) {
      return;
    }

    dispatch(getUserSpecificEmployeeExpenses(employeeId));
  }, [dispatch, employeeId, userRole]);

  useEffect(() => {
    if (employee.userId) {
      dispatch(getDocumentsAction({ userId: employee.userId }));
    }
    return () => dispatch(clearDocumentsAction());
  }, [dispatch, employee.userId]);

  useEffect(() => {
    setCurrentHash(location.hash);
  }, [location]);

  useEffect(() => {
    if (isLSP) {
      setButtonConfig(
        !currentHash || currentHash === '#details'
          ? {
              text: 'Download data',
              type: 'download',
              onClick: async () => {
                const {
                  data: { url },
                } = await getEmployeeDataExportRequest(employeeId);
                window.open(url, '_self');
              },
              disabled: false,
            }
          : {},
      );
    } else {
      const tabsWithoutButtons = [
        '#expenses',
        '#onboarding',
        '#time-off',
        '#employmentFAQ',
      ];

      if (tabsWithoutButtons.includes(currentHash)) {
        setButtonConfig({
          text: '',
          type: '',
          onClick: null,
          disabled: false,
        });
      } else {
        const buttonConfig = {
          '#details': {
            text: 'Edit details',
            type: 'edit',
            onClick: () => {
              appHistory.push(`${EMPLOYEES_PATH}/${employeeId}/edit`);
            },
            disabled: !canEditEmployeeDetails,
          },
          '#documents': {
            text: 'Add document',
            type: 'add',
            onClick: () =>
              appHistory.push(
                addDocumentPath ||
                  `${EMPLOYEES_PATH}/${employeeId}/add-document`,
              ),
            disabled: !canAddDocument,
          },
          '#employmentHistory': isAdmin
            ? {
                text: 'Add employment',
                type: 'add',
                onClick: () => appHistory.push(ADD_EMPLOYMENT_PAGE(employeeId)),
                disabled: isOnboardingInProgress,
              }
            : {},
        };
        setButtonConfig(buttonConfig[currentHash] || buttonConfig['#details']);
      }
    }
  }, [
    canAddDocument,
    canEditEmployeeDetails,
    isOnboardingInProgress,
    currentHash,
    setButtonConfig,
    employeeId,
    addDocumentPath,
    isLSP,
    isAdmin,
  ]);

  useEffect(() => {
    const departmentId = employee?.administrativeInformation?.find(
      (field) => field.name === 'department',
    )?.value;

    const departmentValue = departmentQuery.data?.find(
      (dept) => dept.id === departmentId,
    )?.name;

    if (departmentValue) {
      dispatch(
        updateDepartmentAction({ value: departmentValue, name: 'department' }),
      );
    }
  }, [employee, departmentQuery.data, dispatch]);

  const { name, jobTitle, onboardingStatus, employmentInformation, managerId } =
    employee;

  const shouldShowExpensesTab =
    employeeExpenses?.paid || employeeExpenses?.outstanding;

  const shouldShowTimeOffTab = isTimeOffIntegrationEnabled() && !isLSP;

  const seatQuery = useSingleSeatQuery(employee.externalId, {
    withSignature: true,
    queryOptions: {
      enabled: !isLSP && !!employee.externalId,
      staleTime: Infinity,
    },
  });

  const { data: { name: companyName = '' } = {} } = useCompanyQuery(managerId, {
    enabled: isAdmin || isCSAdmin,
  });

  const DetailsContent = () =>
    seatQuery.isLoading ? (
      <Loading />
    ) : (
      <EmployeeInfoTab
        userId={employee.userId}
        userRole={userRole}
        seat={seatQuery.data}
        documents={employeeDocuments}
      />
    );

  const DocumentsContent = () => (
    <DocumentsTab
      documents={employeeDocuments}
      addDocumentPath={
        addDocumentPath || `${EMPLOYEES_PATH}/${employeeId}/add-document`
      }
      onDocumentDelete={(documentId) =>
        dispatch(deleteDocumentAction({ documentId, userId: employee.userId }))
      }
      canAddDocument={canAddDocument}
      canDeleteDocument={canDeleteDocument}
      canDownloadDocument={canDownloadDocument}
    />
  );

  const ExpensesContent = () => (
    <EmployeeExpensesTab
      expenses={employeeExpenses}
      // TODO -> Send permission from BE
      canSetExpensesToPaidStatus={isAdmin}
    />
  );

  const TimeOffContentComponent = () =>
    employeeTimeOffQuery.isSuccess ? (
      <TimeOffContent data={employeeTimeOffQuery.data} />
    ) : (
      <Loading />
    );

  const EmploymentHistoryContent = () => (
    <EmploymentHistory
      setIsOnboardingInProgress={(isEmployeeOnboardingInProgress) =>
        setIsOnboardingInProgress(isEmployeeOnboardingInProgress)
      }
    />
  );

  const managerTabConfig = [
    {
      id: 'details',
      title: 'Details',
      href: '#details',
      Content: DetailsContent,
    },
    {
      id: 'documents',
      title: 'Documents',
      href: '#documents',
      Content: DocumentsContent,
    },
    ...(shouldShowExpensesTab
      ? [
          {
            id: 'expenses',
            title: 'Expenses',
            href: '#expenses',
            isDisabled:
              // If the user doesn't have permission to view expenses, the response error is forbidden & data undefined
              !employeeExpenses?.paid && !employeeExpenses?.outstanding,
            Content: ExpensesContent,
          },
        ]
      : []),
    ...(shouldShowTimeOffTab
      ? [
          {
            id: 'time-off',
            title: 'Time Off',
            href: '#time-off',
            Content: TimeOffContentComponent,
          },
        ]
      : []),
    ...(!isLSP
      ? [
          {
            id: 'employmentHistory',
            title: 'Employment History',
            href: '#employmentHistory',
            Content: EmploymentHistoryContent,
          },
        ]
      : []),
  ];

  const EmploymentFAQContent = () => (
    <EmploymentFAQTab
      {...{
        employmentInformation,
        saveEmploymentInformation: (data) =>
          dispatch(
            updateEmployeeEmploymentFAQAction({
              employmentInformation: data,
            }),
          ),
      }}
    />
  );

  const employmentHistoryObject = managerTabConfig.find(
    (tab) => tab.id === 'employmentHistory',
  );

  const adminTabConfig = [
    ...managerTabConfig.filter((tab) => tab.id !== 'employmentHistory'),
    {
      id: 'employmentFAQ',
      title: 'My Employment',
      href: '#employmentFAQ',
      Content: EmploymentFAQContent,
    },
    ...(employmentHistoryObject ? [employmentHistoryObject] : []),
  ];

  const currentManagerTab = currentHash
    ? managerTabConfig.filter((tab) => tab.href === currentHash)[0]
    : managerTabConfig[0];

  const currentAdminTab = currentHash
    ? adminTabConfig.filter((tab) => tab.href === currentHash)[0]
    : adminTabConfig[0];

  const employeeCountryFlagEmoji = getCountryFlagByCountryName(
    employee.country,
  );

  const employeeSubtitle = companyName
    ? `${employeeCountryFlagEmoji} ${employee.country} | ${companyName} | ${jobTitle}`
    : `${employeeCountryFlagEmoji} ${employee.country} | ${jobTitle}`;

  return (
    <PageContent>
      {(loading || !name || (isAdmin && !employeeExpenses)) && <Loading />}
      {!loading && name && employeeDocuments && (
        <StyledEmployeeDetailsPage
          data-testid="employee-details-page"
          className="bigStack"
        >
          {visibleModal && (
            <ConfirmationModal
              title={CONFIRMATION_MODAL_TITLE}
              message={confirmationModalMessage.current}
              cancelHandler={() => setVisibleModal(undefined)}
              cancelLabel="CLOSE"
            />
          )}
          <PageHeader
            title={name}
            copy={employeeSubtitle}
            secondaryCta
            ctaText={buttonConfig.text}
            onCTAClick={buttonConfig.onClick}
            hasDisabledCTA={buttonConfig.disabled}
            statusPill={<OnboardingStatus status={onboardingStatus} />}
            image={
              <StyledProfilePicture
                src={avatarPlaceholderPicture}
                alt="Employee profile picture"
              />
            }
            backLink={{
              url: isAdmin
                ? getEmployeeListPath(employee.managerId)
                : EMPLOYEES_PATH,
              label: 'Back to all employees',
            }}
          />
          <Tabs
            tabsToDisplay={isAdmin ? adminTabConfig : managerTabConfig}
            currentTab={isAdmin ? currentAdminTab : currentManagerTab}
          />
          {buttonConfig.type && (
            <Viewport devices={['phone', 'tablet']}>
              <FloatingActionButton
                type={buttonConfig.type}
                onClick={buttonConfig.onClick}
                disabled={buttonConfig.disabled}
              />
            </Viewport>
          )}
        </StyledEmployeeDetailsPage>
      )}
    </PageContent>
  );
};

EmployeeDetailsPage.propTypes = {
  addDocumentPath: PropTypes.string,
};

export default EmployeeDetailsPage;
