import { deleteEmployeeTimeOffLogRequest } from 'common/api/timeOffLogs.api';
import { EmployeeTimeOffLogsResponse } from 'common/types';
import { useMutation, useQueryClient } from 'react-query';
import { useSelectEmployeeId } from 'utils/hooks';

import { timeOffLogsKeys } from '../keys';

export const useDeleteTimeOffMutation = () => {
  const queryClient = useQueryClient();
  const employeeId = useSelectEmployeeId();

  const queryKey = timeOffLogsKeys.singleEmployee(employeeId);

  return useMutation(
    (timeOffLogId: string) => deleteEmployeeTimeOffLogRequest(timeOffLogId),
    {
      onMutate: (timeOffLogId: string) => {
        const previousData =
          queryClient.getQueryData<EmployeeTimeOffLogsResponse>(queryKey);

        if (previousData) {
          queryClient.setQueryData<EmployeeTimeOffLogsResponse>(queryKey, {
            ...previousData,
            scheduled: previousData.scheduled.filter(
              (timeOffLog) => timeOffLog.id !== timeOffLogId,
            ),
          });
        }

        return { previousData };
      },
      onError: (_err, _data, ctx) => {
        if (ctx?.previousData) {
          queryClient.setQueryData<EmployeeTimeOffLogsResponse>(
            queryKey,
            ctx.previousData,
          );
        }
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(queryKey);
      },
    },
  );
};
