import { GrossToNetDetailView } from 'common/types';

import {
  Card,
  Inline,
  Spinner,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

import { formatValueToFixed } from '../../PayReportPreviewPage/PayReportPreviewTable';

export const LSPBillsDetailHeader = ({
  gtnDetails,
  currency,
  payrollCycle,
  isLoading,
}: {
  gtnDetails?: GrossToNetDetailView;
  currency: string;
  payrollCycle: string;
  isLoading: boolean;
}) => {
  if (isLoading) {
    return (
      <Inline
        bg="primary"
        radius="m"
        border="subtle"
        p="16"
        justify="center"
        align="center"
      >
        <Spinner size="16" />
      </Inline>
    );
  }
  return (
    <Card py="24">
      <Inline
        justify="space-between"
        data-testid="lsp-bill-detail-summary"
        stretch="all"
        gap="16"
      >
        <Stack px="24" gap="8" borderRight border="subtle">
          <Typography as="span" weight="medium" size="12" color="subtle">
            Grand Total (Billing Currency)
          </Typography>
          <Typography as="span" weight="bold" size="16">
            {currency}&nbsp;
            {formatValueToFixed(gtnDetails?.grandTotalInBillingCurrency || 0)}
          </Typography>
        </Stack>
        <Stack gap="8" borderRight border="subtle">
          <Typography as="span" weight="medium" size="12" color="subtle">
            Uploaded employees
          </Typography>
          <Typography as="span" weight="bold" size="16">
            {gtnDetails?.uploadedEmployees}
          </Typography>
        </Stack>
        <Stack gap="8" borderRight border="subtle">
          <Typography as="span" weight="medium" size="12" color="subtle">
            Approved records
          </Typography>
          <Typography as="span" weight="bold" size="16">
            {gtnDetails?.approvedRecords} / {gtnDetails?.uploadedRecords}
          </Typography>
        </Stack>
        <Stack gap="8" borderRight border="subtle">
          <Typography as="span" weight="medium" size="12" color="subtle">
            Published records
          </Typography>
          <Typography as="span" weight="bold" size="16">
            {gtnDetails?.publishedRecords} / {gtnDetails?.uploadedRecords}
          </Typography>
        </Stack>
        <Stack gap="8">
          <Typography as="span" weight="medium" size="12" color="subtle">
            Payroll cycle
          </Typography>
          <Typography as="span" weight="bold" size="16">
            Cycle {payrollCycle}
          </Typography>
        </Stack>
      </Inline>
    </Card>
  );
};
