import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import { getUserTenantId } from 'app/store/app.selectors';
import { Loading } from 'common/components';
import { ADD_COMPENSATION_PATH, BULK_COMPENSATION_PATH } from 'paths';
import { useSelector } from 'react-redux';

import {
  Breadcrumbs,
  Button,
  Inline,
  Stack,
  TabProps,
  Tabs,
  Typography,
} from '@omnipresentgroup/design-system';

import { SalaryHistoryList } from '../components';
import { CompensationRequestList } from '../components/CompensationRequestList/CompensationRequestList';

export const PAGE_TITLE = 'Compensation';

export enum TabNames {
  SALARY_CHANGE_REQUESTS = 'Salary change requests',
  VARIANT_PAYMENT_REQUESTS = 'Variable pay requests',
}

export enum TabHashes {
  SALARY_HISTORY = '#salary-history',
  VARIANT_PAYMENTS = '#variant-payments',
}

export const CompensationPage = () => {
  const history = useHistory();
  const location = useLocation();
  const userTenantId = useSelector(getUserTenantId);
  const defaultTab = Object.values(TabHashes).includes(
    location.hash as TabHashes,
  )
    ? (location.hash as TabHashes)
    : TabHashes.VARIANT_PAYMENTS;

  const [selectedTab, setSelectedTab] = useState<TabHashes>(defaultTab);

  useEffect(() => {
    history.replace({ ...window.location, hash: defaultTab });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabClick = (tab: TabProps) => {
    const hash = tab.id as TabHashes;
    history.push({ ...location, hash });
    setSelectedTab(hash);
  };

  return (
    <PageContent style={{ position: 'relative' }}>
      <Stack>
        <Stack gap="16">
          <Breadcrumbs
            crumbs={[
              { id: 'home', title: 'Home', onClick: () => history.push('/') },
              { id: 'compensation', title: PAGE_TITLE, active: true },
            ]}
          />
          <Stack gap="32">
            <Stack gap="16">
              <Stack gap="32">
                <Inline
                  justify="space-between"
                  overflow="auto"
                  align="center"
                  minH={40}
                >
                  <Typography
                    as="h2"
                    hideParagraphSpacing
                    size="24"
                    weight="medium"
                  >
                    {PAGE_TITLE}
                  </Typography>
                  <Inline>
                    <Button
                      id="compensation-bulk-upload"
                      variant="secondary"
                      onClick={() => history.push(BULK_COMPENSATION_PATH)}
                    >
                      Upload bulk changes
                    </Button>
                    <Button
                      id="compensation-submit-change"
                      onClick={() => history.push(ADD_COMPENSATION_PATH)}
                    >
                      Submit compensation change
                    </Button>
                  </Inline>
                </Inline>
              </Stack>
              <Tabs
                controlledTabId={selectedTab}
                onTabClicked={handleTabClick}
                tabsToDisplay={[
                  {
                    disabled: false,
                    id: TabHashes.VARIANT_PAYMENTS,
                    title: TabNames.VARIANT_PAYMENT_REQUESTS,
                  },
                  {
                    disabled: false,
                    id: TabHashes.SALARY_HISTORY,
                    title: TabNames.SALARY_CHANGE_REQUESTS,
                  },
                ]}
              />
            </Stack>
          </Stack>
        </Stack>
        {!userTenantId ? (
          <Loading />
        ) : (
          <>
            {selectedTab === TabHashes.VARIANT_PAYMENTS && (
              <CompensationRequestList companyId={userTenantId} />
            )}
            {selectedTab === TabHashes.SALARY_HISTORY && (
              <SalaryHistoryList companyId={userTenantId} />
            )}
          </>
        )}
      </Stack>
    </PageContent>
  );
};
