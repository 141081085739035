import JoiImport from 'joi';

import DateExtension from '@omnipresentgroup/joi-date';

const Joi = JoiImport.extend(DateExtension) as JoiImport.Root;

export const addEmployeeTimeOffFormConfig = {
  formTitle: 'Add a time off',
  formCopy: 'Any updates you make will be shared with your manager.',
  stages: [
    {
      name: '',
      stage: 1,
      fields: [
        {
          name: 'leaveType',
          label: 'Leave type',
          type: 'leaveType',
          value: '',
          error: null,
          required: true,
        },
        {
          name: 'startDate',
          label: 'Start date',
          type: 'date',
          value: '',
          error: null,
          required: true,
        },
        {
          name: 'endDate',
          label: 'End date',
          type: 'date',
          value: '',
          error: null,
          required: true,
        },
        {
          name: 'employeeNote',
          label: 'Note',
          type: 'text',
          value: '',
          error: null,
          required: false,
        },
        {
          name: 'hasConfirmedWithManager',
          label:
            'By checking this box, you are confirming that this time off was already approved by your manager',
          type: 'checkbox-boolean',
          value: false,
          error: null,
          required: true,
        },
      ],
      schema: Joi.object().keys({
        leaveType: Joi.string()
          .required()
          .messages({ 'string.empty': 'Please choose a leave type' }),
        startDate: Joi.date().required().format('YYYY-MM-DD').raw().messages({
          'date.empty': 'Please enter a start date',
          'date.format': 'Please enter a valid start date',
        }),
        endDate: Joi.when('startDate', {
          is: Joi.date().valid(),
          then: Joi.date()
            .min(Joi.ref('startDate'))
            .required()
            .format('YYYY-MM-DD')
            .raw()
            .messages({
              'date.empty': 'Please enter an end date',
              'date.format': 'Please enter a valid end date',
              'date.min':
                'Please enter an end date greater or equal to the start date',
            }),
        }),
        employeeNote: Joi.string().optional().allow('', null),
        hasConfirmedWithManager: Joi.boolean().valid(true).required().messages({
          'any.only': 'Only approved time offs are allowed to be added',
        }),
      }),
    },
  ],
};
