import {
  getSalaryHistory,
  GetSalaryHistoryResponse,
  QueryParams,
} from 'common/api/salaryHistory.api';
import { SALARY_HISTORY } from 'paths';
import { useQuery } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

export const useGetSalaryHistory = ({
  companyId,
  params,
}: {
  companyId: string;
  params?: QueryParams;
}) => {
  return useQuery<
    GetSalaryHistoryResponse,
    Error,
    GetSalaryHistoryResponse['salaryHistory']
  >(
    [SALARY_HISTORY, companyId, JSON.stringify(params)],
    () => getSalaryHistory({ companyId, params }).then(getDataFromResponse),
    {
      enabled: Boolean(companyId),
      select: (data) => data.salaryHistory,
    },
  );
};
