/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import appHistory from 'app/appHistory';
import { getUserSelector } from 'app/store/app.selectors';
import { AxiosError } from 'axios';
import { ErrorBanner, Loading } from 'common/components';
import MobileButton from 'common/components/atoms/MobileButton/MobileButton';
import { StyledBoldCopy } from 'common/components/atoms/Text/Copy.styles';
import { ConfirmationModal } from 'common/components/molecules/ConfirmationModal';
import EditableInput, {
  getDependentFieldOptions,
} from 'common/components/molecules/EditableInput/EditableInput';
import { FormCheckbox } from 'common/components/molecules/FormCheckbox';
import PageHeader from 'common/components/molecules/PageHeader/PageHeader';
import { FourOhFour } from 'common/pages/404/FourOhFour';
import { getEditableDetailsByUserType } from 'common/pages/EditEmployeePage/getEditableDetailsByUser';
import { Section } from 'common/pages/EmployeeDetailsPage/Tabs/EmployeeInfoTab/EmployeeInfoTab';
import {
  CS_PARTY_ID,
  generateEmployeeFields,
  transformEmployeePayLoad,
} from 'common/pages/SeatToEmployeePage/config/generateContractDetailsStage';
import {
  Contract,
  ContractStatus,
  DRAFT,
  EntryDefinition,
  Field,
  FIELD_TYPE,
  REVIEW_DONE,
  SIGNED_BOTH_PARTIES,
} from 'common/types';
import pick from 'lodash/pick';
import { EMPLOYEES_PATH } from 'paths';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useContractValues } from 'utils/hooks';
import { mapCountryNameToCountryCode } from 'utils/mapCountryNameToCountryCode';
import { arePreconditionsMet } from 'utils/preconditions';
import {
  useAvvokaTemplateQuery,
  useUpdateEmployeeMutation,
} from 'utils/queries';
import { useUpdateContractQuestionnaireMutation } from 'utils/queries/contractQuestionnaire';
import { UpdatContractQuestionnaire } from 'utils/queries/contractQuestionnaire/useUpdateContractQuestionnaireMutation';
import { useEmployeeQuery } from 'utils/queries/employees/useEmployeeQuery';
import { filterRedactedDetails } from 'utils/rbac.utils';
import { reduceInputField } from 'utils/transformer-utils/input.transformer';
import { isCSAdmin, isOPAdmin } from 'utils/user';
import { getLocalOnboardingSchema } from 'utils/validators/employee.schema';
import { validateInputs } from 'utils/validators/validator';

import {
  Button,
  Inline,
  Notification,
  NotificationIntent,
  Viewport,
} from '@omnipresentgroup/design-system';

import * as S from './EditContract.styles';

export enum EditMode {
  generate_new_contract = 'generate-contract',
  update_contract_answers = 'edit-answers',
  complete_missing_information = 'complete-information',
}
export enum EditContractLabel {
  generate_new_contract = 'Generate new contract',
  update_contract_answers = 'Update contract answers',
  complete_missing_information = 'Complete missing information',
}
export enum EditContractAlert {
  generate_new_contract = `You are about to update the answers of the contract questionnaire, this will 
  generate a new version of the contract and will set its status to draft and update the employee's information`,
  update_contract_answers = `You are about to update the answers of the contract questionnaire only. 
  This won't generate a new version of the contract, it will only update the employee's information`,
  complete_missing_information = 'You need to provide some missing information to generate the employment contract',
}

export enum EditContractConfirmation {
  generate_new_contract = `Confirming this action will update the contract answers, create a new version of the contract and send it to the Client Manager.`,
  update_contract_answers = 'Confirming this action will only update the contract answers.',
  complete_missing_information = 'Confirming this action will create the contract with the missing information.',
}

type PageSettings = {
  cta: string;
  label: string;
  title: string;
  alertText: string;
  alertIntent: NotificationIntent;
  modalConfirmMessage: string;
};

type MultiDocForm = {
  addDocsUploaded?: string;
};

const getEditSettings = (editMode: EditMode | ''): PageSettings => {
  switch (editMode) {
    case EditMode.update_contract_answers:
      return {
        cta: EditContractLabel.update_contract_answers,
        label: EditContractLabel.update_contract_answers,
        title: EditContractLabel.update_contract_answers,
        alertText: EditContractAlert.update_contract_answers,
        alertIntent: 'warning',
        modalConfirmMessage: EditContractConfirmation.update_contract_answers,
      };
    case EditMode.complete_missing_information:
      return {
        cta: EditContractLabel.complete_missing_information,
        label: EditContractLabel.complete_missing_information,
        title: EditContractLabel.complete_missing_information,
        alertText: EditContractAlert.complete_missing_information,
        alertIntent: 'warning',
        modalConfirmMessage:
          EditContractConfirmation.complete_missing_information,
      };
    default:
      return {
        cta: EditContractLabel.generate_new_contract,
        label: EditContractLabel.generate_new_contract,
        title: EditContractLabel.generate_new_contract,
        alertText: EditContractAlert.generate_new_contract,
        alertIntent: 'warning',
        modalConfirmMessage: EditContractConfirmation.generate_new_contract,
      };
  }
};

const setSearchParam = (param: EditMode) => {
  appHistory.replace({
    pathname: location.pathname,
    search: param,
  });
};

const mapFieldValues = (field: any, name: string, value: any) => {
  switch (field.type) {
    case FIELD_TYPE.CHECKBOX:
    case FIELD_TYPE.DROPDOWN:
      return [{ ...field, value }].reduce(
        // @ts-ignore
        reduceInputField,
        {},
      );
    default:
      return { [name]: value };
  }
};

/* NB: this page is now catering for 3 cases, it could be refactored to use
a hook exposing fields for each role type and editMode
questions should be grouped by party and ensure "Party A" or manager questions work
as expected */
export const EditContract = () => {
  const { employeeId, documentId } = useParams<{
    employeeId: string;
    documentId: string;
  }>();
  const {
    data: employee,
    isError: isErrorEmployee,
    error: employeeError,
  } = useEmployeeQuery(employeeId);

  const { mutate: updateEmployee, isLoading: isUpdatingEmployee } =
    useUpdateEmployeeMutation();

  const user = useSelector(getUserSelector);

  const location = useLocation();

  // @ts-ignore - improper type on useEmployeeQuery
  const countryCode = mapCountryNameToCountryCode(employee?.country.name);
  const companyId = employee?.managerId;

  const editModeParam = Object.keys(
    queryString.parse(location.search),
  )[0] as EditMode;

  const [editMode, setEditMode] = useState<EditMode>(editModeParam);
  const [editedValues, setEditedValues] = useState<any>({});
  const [formValues, setFormValues] = useState({});
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [showModal, setShowModal] = useState<boolean>(false);

  // @ts-ignore - improper type on useEmployeeQuery
  const currentContract = employee?.contracts?.find(
    (c: Contract) => c.contractId === documentId || c.documentId === documentId,
  );

  const canGenerateNewContract =
    [
      `${EditMode.generate_new_contract}`,
      `${EditMode.update_contract_answers}`,
    ].includes(editMode) &&
    !!currentContract?.avvokaDocumentId &&
    currentContract.status !== SIGNED_BOTH_PARTIES;

  const isAllowedUser = isCSAdmin(user) || isOPAdmin(user);

  const shouldFetchTemplates =
    !!countryCode &&
    !!companyId &&
    Boolean(
      // @ts-ignore - improper type on useEmployeeQuery
      employee?.contractQuestionnaire?.body &&
        // @ts-ignore - improper type on useEmployeeQuery
        Object.keys(employee?.contractQuestionnaire?.body).length > 0,
    );

  const {
    data: templates,
    isError: isErrorTemplates,
    isLoading: isLoadingTemplates,
    error: templatesError,
  } = useAvvokaTemplateQuery({
    countryCode,
    enabled: shouldFetchTemplates,
    companyId: companyId as string,
  });

  const entryDefinitions = templates?.[0]
    ?.entryDefinitions as EntryDefinition[];

  useEffect(() => {
    const isMultyParty = entryDefinitions?.some(
      (attribute) => attribute.party_id !== entryDefinitions[0]?.party_id,
    );
    // By default we redirect to generate contract mode
    if (!(Object.values(EditMode) as EditMode[]).includes(editMode)) {
      setEditMode(EditMode.generate_new_contract);
      setSearchParam(EditMode.generate_new_contract);
    }
    if (isMultyParty) {
      setEditMode(EditMode.complete_missing_information);
      setSearchParam(EditMode.complete_missing_information);
    } else {
      // then whever the edit mode changes we push to the according route
      setSearchParam(editMode);
    }
  }, [editMode, entryDefinitions]);

  const { mutate: createContract, isLoading: isCreatingContract } =
    useUpdateContractQuestionnaireMutation();

  const {
    fields: contractFields,
    schema: contractSchema,
  }: { fields: Field[]; schema: any } = useMemo(() => {
    return generateEmployeeFields(
      employee,
      entryDefinitions,
      isCSAdmin(user) || (isOPAdmin(user) as boolean),
      false,
    );
  }, [employee, entryDefinitions, user]);

  useEffect(() => {
    if (contractFields.length > 0) {
      setSectionsToEdit(
        getEditableDetailsByUserType(employeeDetailsSections, user),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractFields, employee]);

  const contractFieldsCS = contractFields.filter(
    (a) => a.party_id === CS_PARTY_ID,
  );
  const contractFieldsManager = contractFields.filter(
    (a) => a.party_id !== CS_PARTY_ID,
  );

  const employeeDetailsSections = useMemo(() => {
    return [
      contractFieldsCS.length > 0 && {
        key: `contract-details-${CS_PARTY_ID}`,
        name: `contract-details-${CS_PARTY_ID}`,
        title: 'CS questions',
        details: contractFieldsCS,
      },
      contractFieldsManager.length > 0 && {
        key: 'contract-details',
        name: 'contract-details',
        title: 'Contract details',
        details: contractFieldsManager,
      },
    ].filter(Boolean) as Section[];
  }, [contractFieldsManager, contractFieldsCS]);

  const [sectionsToEdit, setSectionsToEdit] = useState(employeeDetailsSections);

  useEffect(() => {
    // @ts-ignore - improper type on useEmployeeQuery
    if (contractFields.length > 0 && Object.keys(employee).length > 0) {
      setFormValues(
        filteredSectionsToEdit.reduce(
          (acc, section) => ({
            ...acc,
            // @ts-ignore
            ...(section?.details || []).reduce(reduceInputField, {}),
          }),
          {},
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee, sectionsToEdit, contractFields]);

  const filteredSectionsToEdit = filterRedactedDetails(
    sectionsToEdit,
  ) as Section[];

  const backLink =
    editMode === EditMode.complete_missing_information
      ? {
          url: `${EMPLOYEES_PATH}/${employeeId}`,
          label: "Back to employee's details",
        }
      : {
          url: `${EMPLOYEES_PATH}/${employeeId}/contracts/${documentId}`,
          label: "Back to contract's details",
        };

  const contractValues = useContractValues(sectionsToEdit, formValues);

  const isMultiDoc =
    Object.keys(formValues).length &&
    formValues.hasOwnProperty('addDocsUploaded');

  const canBeSubmitted = isMultiDoc
    ? (formValues as MultiDocForm).addDocsUploaded === 'Yes'
    : true;

  const disabled =
    !isAllowedUser ||
    isLoadingTemplates ||
    sectionsToEdit.length === 0 ||
    !canBeSubmitted;

  const onFieldChange = (name: any, value: any) => {
    const currentSectionsToEdit = sectionsToEdit.map((section: any) => {
      return {
        ...section,
        details: section.details.map((field: Field) => {
          if (field.name !== name) {
            return field;
          }
          setEditedValues((currentEditedValues: any) => ({
            ...currentEditedValues,
            ...mapFieldValues(field, name, value),
          }));
          setFormValues((currentFormValues: any) => ({
            ...currentFormValues,
            ...mapFieldValues(field, name, value),
          }));
          return {
            ...field,
            value,
          };
        }),
      };
    });
    setSectionsToEdit(currentSectionsToEdit as any);
  };

  const validator = (fields: Field[]) =>
    validateInputs(fields, validationSchema);

  const goToEmployeeDetailsPage = () => {
    appHistory.replace(`${EMPLOYEES_PATH}/${employeeId}`);
  };
  const goToContractDetailsPage = (contractId: string) => {
    appHistory.replace(
      `${EMPLOYEES_PATH}/${employeeId}/contracts/${contractId}`,
    );
  };

  const onCTAClick = () => {
    const data = {
      ...editedValues,
      ...contractValues,
    };
    const validationResult = validator(data);
    if (validationResult) {
      setValidationErrors(validationResult);
      return;
    }
    setValidationErrors({});
    setShowModal(true);
  };

  const getErrorMessage = (name: string) =>
    validationErrors?.[name] ? validationErrors?.[name] : null;

  const validationSchema = getLocalOnboardingSchema(
    // @ts-ignore
    employee?.localOnboardingDetails,
  ).append(contractSchema);

  const confirmContractCreation = (status: ContractStatus = DRAFT) => {
    const data = {
      ...editedValues,
      ...contractValues,
    };
    let transformValues = data;
    if (entryDefinitions && entryDefinitions.length > 0 && isAllowedUser) {
      transformValues = transformEmployeePayLoad(
        data,
        templates?.[0]?.id,
        entryDefinitions,
      );
    }
    const filteredQuestionnaire = pick(transformValues, [
      'contractQuestionnaire',
    ]);
    const updatePayload: UpdatContractQuestionnaire = {
      questionnaire: filteredQuestionnaire,
      status,
      companyId: companyId as string,
    };

    createContract(updatePayload, {
      onSuccess: (response) => {
        goToContractDetailsPage(response.data.contractId);
      },
      onError: (err) => {
        console.error(err);
        setShowModal(false);
      },
    });
  };

  const confirmUpdateAnswers = () => {
    const data = {
      ...editedValues,
      ...contractValues,
    };
    let transformValues = data;
    if (entryDefinitions && entryDefinitions.length > 0 && isAllowedUser) {
      transformValues = transformEmployeePayLoad(
        data,
        templates?.[0]?.id,
        entryDefinitions,
      );
    }
    const filteredQuestionnaire = pick(transformValues, [
      'contractQuestionnaire',
    ]);

    updateEmployee(
      // @ts-ignore
      { infoToUpdate: filteredQuestionnaire, employeeId },
      {
        onSuccess: () => {
          setShowModal(false);
          goToEmployeeDetailsPage();
        },
        onError: (err) => {
          alert(err);
          setShowModal(false);
        },
      },
    );
  };

  const getModalProps = () => {
    switch (editMode) {
      case EditMode.complete_missing_information:
        return {
          cancelHandler: () => setShowModal(false),
          cancelLabel: 'Cancel',
          confirmHandler: () => {
            confirmContractCreation(REVIEW_DONE);
          },
          confirmLabel: EditContractLabel.complete_missing_information,
          loading: isCreatingContract,
          message: EditContractConfirmation.complete_missing_information,
          title: EditContractConfirmation.complete_missing_information,
        };
      case EditMode.update_contract_answers:
        return {
          cancelHandler: () => setShowModal(false),
          cancelLabel: 'Cancel',
          confirmHandler: confirmUpdateAnswers,
          confirmLabel: EditContractLabel.update_contract_answers,
          loading: isUpdatingEmployee,
          message: EditContractConfirmation.update_contract_answers,
          title: EditContractLabel.update_contract_answers,
        };
      default:
        return {
          cancelHandler: () => setShowModal(false),
          cancelLabel: 'Cancel',
          confirmHandler: () => {
            confirmContractCreation(DRAFT);
          },
          confirmLabel: EditContractLabel.generate_new_contract,
          loading: isCreatingContract,
          message: EditContractConfirmation.generate_new_contract,
          title: EditContractLabel.generate_new_contract,
        };
    }
  };

  const isError = isErrorTemplates || isErrorEmployee;

  const getAPIErrorMessage = (error: AxiosError) => {
    return `The request failed because: ${error.response?.data.message}`;
  };

  const { cta, title, alertText, alertIntent } = getEditSettings(editMode);

  if (Object.keys(user).length > 0 && !isAllowedUser) {
    return <FourOhFour />;
  }

  return (
    <PageContent key="edit-contract-page">
      <div className="smallStack">
        <PageHeader title={title} backLink={backLink} />
      </div>
      <>
        {isError ? (
          <ErrorBanner
            errorMessage={getAPIErrorMessage(
              (templatesError as AxiosError) || (employeeError as AxiosError),
            )}
          />
        ) : (
          <>
            {showModal && <ConfirmationModal {...getModalProps()} />}
            <S.Container className="smallStack">
              <S.AlertContainer>
                {editMode && (
                  <Notification
                    id={editMode}
                    intent={alertIntent}
                    title={alertText}
                  />
                )}
              </S.AlertContainer>
              <S.RequiredFields>* required field</S.RequiredFields>
              {(sectionsToEdit.length === 0 ||
                isLoadingTemplates ||
                !employee) && (
                <S.SectionContainerWrapper>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Loading />
                  </div>
                </S.SectionContainerWrapper>
              )}
              {filteredSectionsToEdit.map((detailSection: any) => (
                <S.SectionContainerWrapper key={detailSection.title}>
                  <div key={detailSection.title} className="smallStack">
                    <StyledBoldCopy>{detailSection.title}</StyledBoldCopy>
                    {(detailSection?.details || [])
                      .filter(
                        (field: any) =>
                          !field.condition ||
                          arePreconditionsMet(field.condition, formValues),
                      )
                      .map((field: Field) => {
                        const formField = { ...field };
                        const {
                          name,
                          type,
                          value,
                          label,
                          noLabel,
                          contextualInfo,
                          readOnly,
                          required,
                          options,
                          placeholder,
                        } = field;
                        return (
                          <EditableInput
                            key={name}
                            options={
                              field.type === FIELD_TYPE.DEPENDENT_DROPDOWN
                                ? getDependentFieldOptions(
                                    formField,
                                    formValues,
                                  )
                                : options
                            }
                            placeholder={placeholder}
                            // @ts-ignore - improper type on useEmployeeQuery
                            country={employee?.country.name}
                            noLabel={noLabel}
                            name={name}
                            type={type}
                            value={value}
                            label={label}
                            editable={!readOnly}
                            onChange={onFieldChange}
                            contextualInfo={contextualInfo}
                            errorMessage={getErrorMessage(name)}
                            required={required}
                          />
                        );
                      })}
                  </div>
                </S.SectionContainerWrapper>
              ))}
              <div className="smallStack">
                {canGenerateNewContract && (
                  <FormCheckbox
                    name="generateContract"
                    id="generateContract-checkbox"
                    defaultChecked={editMode === EditMode.generate_new_contract}
                    onChange={() => {
                      setEditMode((prev) => {
                        return prev === EditMode.generate_new_contract
                          ? EditMode.update_contract_answers
                          : EditMode.generate_new_contract;
                      });
                    }}
                    label={EditContractLabel.generate_new_contract}
                  />
                )}
              </div>
              <Inline justify="space-between">
                <Button
                  variant="secondary"
                  onClick={
                    editMode === EditMode.complete_missing_information
                      ? () => goToEmployeeDetailsPage()
                      : () => goToContractDetailsPage(documentId)
                  }
                >
                  Cancel
                </Button>
                <Button disabled={disabled} onClick={onCTAClick}>
                  {cta}
                </Button>
              </Inline>
              <Viewport devices={['phone', 'tablet']}>
                <MobileButton onClick={onCTAClick} disabled={disabled}>
                  {cta}
                </MobileButton>
              </Viewport>
            </S.Container>
          </>
        )}
      </>
    </PageContent>
  );
};
