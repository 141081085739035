import { HTTPStatusCodesEnum } from 'common/constants';
import { composeEndpoint } from 'mocks/utils';
import { rest } from 'msw';
import { mockBalances } from 'omniplatform/manager/__mocks__/managerInvoiceBalances.mock';

import { financialDocsClient } from '../../api';

export const invoiceReportHandlers = [
  rest.get(
    composeEndpoint(
      'invoice-reports/companies/:companyId/count/',
      financialDocsClient,
    ),
    (req, res, ctx) =>
      res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json({ count: '3' })),
  ),
  rest.get(
    composeEndpoint(
      'invoice-reports/companies/:companyId/balances/',
      financialDocsClient,
    ),
    (req, res, ctx) =>
      res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(mockBalances)),
  ),
  rest.get(
    composeEndpoint(
      'invoice-reports/companies/:companyId/balances/status',
      financialDocsClient,
    ),
    (req, res, ctx) =>
      res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json({ overdue: true })),
  ),
];
