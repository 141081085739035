import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const StyledTimeOffContainer = styled.div`
  width: 100%;
  height: 100%;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    margin-top: ${({ theme: { space } }) => `${space[8]}`};
  }
`;
