import { useState } from 'react';

import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { FIVE } from 'common/constants';
import { SALARY_HISTORY } from 'paths';
import { useGetSalaryHistory } from 'utils/queries/salaryHistory';

import {
  Box,
  Button,
  ContentState,
  Stack,
  Table,
} from '@omnipresentgroup/design-system';

import { TabNames } from '../../CompensationPage/CompensationPage';
import { CompensationNotice } from '../CompensationNotice/CompensationNotice';
import { Columns, LoadingColumns } from './salaryHistoryListColumnConfig';

const DEFAULT_PAGE_SIZE = 10;

export const ERROR_STATE_TITLE = "Uh-oh, something's gone wrong";
export const ERROR_STATE_DESCRIPTION =
  'Try reloading the page below, and if this fails get in touch with our support team.';

export const SalaryHistoryList = ({ companyId }: { companyId: string }) => {
  const DEFAULT_SORT = [
    {
      id: 'requestedAt',
      desc: true,
    },
  ];

  const [sorting, setSorting] = useState<SortingState>(DEFAULT_SORT);

  const {
    data = [],
    isLoading,
    isError,
  } = useGetSalaryHistory({
    companyId,
    params: {
      pageSize: DEFAULT_PAGE_SIZE,
    },
  });

  const table = useReactTable({
    // Core Config
    data,
    columns: Columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
    },
    // Sorting Config
    sortDescFirst: true,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  const loadingTable = useReactTable({
    // Core Config
    data: Array.from({ length: FIVE }, (_value, index) => index),
    columns: LoadingColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Stack gap="24">
      {isError ? (
        <Box bg="primary" radius="m" border="subtle" overflow="hidden">
          <ContentState
            id={`${SALARY_HISTORY}-error`}
            variant="error"
            title={ERROR_STATE_TITLE}
            description={ERROR_STATE_DESCRIPTION}
            callToAction={
              <Button onClick={window.location.reload}>Retry</Button>
            }
          />
        </Box>
      ) : (
        <Stack gap="32">
          <CompensationNotice />
          <Table
            id={`${SALARY_HISTORY}-table`}
            title={TabNames.SALARY_CHANGE_REQUESTS}
            table={isLoading ? loadingTable : table}
            usePagination
            useSorting
          />
        </Stack>
      )}
    </Stack>
  );
};
