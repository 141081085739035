/* eslint-disable max-lines-per-function */
import { useCallback, useEffect, useRef, useState } from 'react';

import {
  getDocumentUploadingSelector,
  getUploadedDocumentLocation,
} from 'app/store/selectors/documentStorage.selectors';
import { HRISFormIntegrationContextProvider } from 'common/contexts/hrisFormIntegration';
import { uploadDocumentAction } from 'common/store/actions/documentStorage.actions';
import {
  getDocumentFileInfoSelector,
  getDocumentUploadedSelector,
} from 'common/store/selectors/documentStorage.selectors';
import { FIELD_TYPE } from 'common/types';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isHRISIntegrationEnabled } from 'utils/featureFlags';
import { arePreconditionsMet } from 'utils/preconditions';
import {
  reduceFormCheckboxValue,
  reduceInputField,
} from 'utils/transformer-utils/input.transformer';

import {
  Box,
  Inline,
  Notification,
  Typography,
  Viewport,
} from '@omnipresentgroup/design-system';

import Button from '../../atoms/Button/Button';
import Loading from '../../atoms/Loading/Loading';
import MobileButton from '../../atoms/MobileButton/MobileButton';
import { FormHRISIntegrationSection } from '../../molecules/FormHRISIntegrationSection';
import FormPage from '../../molecules/FormPage/FormPage';
import {
  StyledFormContainer,
  StyledRightAlignButton,
  StyledStagedForm,
  StyledStagedFormHeader,
  StyledStagedFormPage,
  StyledStagedFormRequiredFields,
  StyledStagedFormWrapper,
} from './StagedForm.styles';

const StagedForm = ({
  formConfig,
  submissionSuccess,
  loading,
  uploadDocument,
  onStageChanged,
  onFormComplete,
  onFormSubmit,
  documentUploading,
  disableCTA,
  country,
  fileInfo,
  fileUploaded,
  uploadedDocumentLocation,
  withHRISIntegration,
  successScreenPayload,
  onExitClick,
  onFormChangeHandler,
  customSuccessCTAText,
}) => {
  const { stages } = formConfig;
  const formRef = useRef(null);

  const [formStages, setFormStages] = useState(stages);
  const [hrisIntegrationDetails, setHrisIntegrationDetails] =
    useState(undefined);
  const [currentStageNumber, setCurrentStageNumber] = useState(1);
  const [onLastStage, setOnLastStage] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(country);
  const [onSubmit, setOnSubmit] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [documentLocationUpdated, setDocumentLocationUpdated] = useState(false);

  const isHRISIntegrationActive =
    isHRISIntegrationEnabled() && withHRISIntegration;
  const clearIntegrationData = useCallback(() => {
    setFormStages(stages);
    setHrisIntegrationDetails(undefined);
    setOnLastStage(false);
    setOnSubmit(false);
    setDocumentLocationUpdated(false);
    onStageChanged(0);
  }, [stages, onStageChanged]);

  const fillIntegrationData = useCallback(
    (integrationDetails, fillFormStages) => {
      setHrisIntegrationDetails(integrationDetails);
      setFormStages(fillFormStages);
    },
    [],
  );

  useEffect(() => {
    document.getElementsByTagName('main')?.[0]?.scrollIntoView();
  }, [currentStageNumber]);

  const onBackClicked = () => {
    if (currentStageNumber > 1) {
      setCurrentStageNumber(currentStageNumber - 1);
    }
  };

  const checkFileUpload = useCallback(() => {
    const fields = formStages.map((stage) => stage.fields).flat();

    const FILE_TYPES = ['document', 'receipt'];

    return (
      fields.filter(
        (field) =>
          FILE_TYPES.includes(field.type) && (field.required || field.value),
      ).length > 0
    );
  }, [formStages]);

  useEffect(() => {
    setFormValues(
      stages.reduce(
        (acc, stage) => ({
          ...acc,
          ...stage.fields.reduce(reduceInputField, {}),
        }),
        {},
      ),
    );
  }, [stages]);

  useEffect(() => {
    setOnLastStage(formStages.length === currentStageNumber);
  }, [currentStageNumber, setOnLastStage, formStages.length]);

  useEffect(() => {
    const hasFileUpload = checkFileUpload();
    if (onSubmit) {
      if (
        (hasFileUpload && fileUploaded && documentLocationUpdated) ||
        !hasFileUpload
      ) {
        onFormSubmit(formStages, hrisIntegrationDetails);
        setOnSubmit(false);
      }
    }
  }, [
    hrisIntegrationDetails,
    documentLocationUpdated,
    fileUploaded,
    formStages,
    checkFileUpload,
    onFormSubmit,
    onSubmit,
  ]);

  const updateStageFields = (newFields) => {
    const updatedStages = formStages.map((stage) => {
      if (currentStageNumber === stage.stage) {
        return {
          ...stage,
          fields: newFields,
        };
      }
      return stage;
    });

    if (onFormChangeHandler) {
      onFormChangeHandler(updatedStages);
    }

    setFormStages(updatedStages);
  };

  const onFormChanged = (name, value) => {
    const isLocationUpdated =
      !documentLocationUpdated && uploadedDocumentLocation === value;
    setDocumentLocationUpdated(isLocationUpdated);

    const fieldsToUpdate = formStages[currentStageNumber - 1].fields;

    if (name === 'country') {
      setSelectedCountry(value);
    }

    const updatedFields = fieldsToUpdate.map((field) => {
      if (field.name === name) {
        setFormValues({
          ...formValues,
          ...[{ ...field, value }].reduce(reduceInputField, {}),
        });
        return {
          ...field,
          value,
        };
      }
      return field;
    });

    updateStageFields(updatedFields);
  };

  const getValidationErrors = () => {
    const currentStage = formStages.find(
      ({ stage }) => stage === currentStageNumber,
    );

    if (!currentStage.fields.length) {
      return null;
    }

    const objectForValidation = currentStage.fields.reduce((result, item) => {
      const newResult = { ...result };
      if (item.type === FIELD_TYPE.CHECKBOX) {
        newResult[item.name] = reduceFormCheckboxValue(item.value);
      } else {
        newResult[item.name] = item.value;
      }
      return newResult;
    }, {});
    const { error: validationError } = currentStage.schema.validate(
      objectForValidation,
      {
        abortEarly: false,
      },
    );
    let errorDetails = validationError?.details;
    if (errorDetails && currentStage.fields.some((field) => field.condition)) {
      errorDetails = errorDetails.filter(({ context: { key } }) => {
        const conditionalInput = currentStage.fields.find(
          (field) => field.condition && field.name === key,
        );
        if (conditionalInput) {
          return arePreconditionsMet(conditionalInput.condition, formValues);
        }
        return true;
      });
    }
    const errorObject =
      errorDetails &&
      errorDetails.length &&
      errorDetails.reduce((result, { context, message, path }) => {
        const newErrorObject = { ...result };
        if (
          path.length > 1 &&
          ['annualBaseSalary', 'holidayEntitlement'].includes(path[0])
        ) {
          newErrorObject[path[0]] = message;
          return newErrorObject;
        }
        const addressKeys = ['addressLine1', 'city', 'postalCode'];
        if (addressKeys.includes(context.key)) {
          newErrorObject.address = {
            ...newErrorObject.address,
            [context.key]: message,
          };
          return newErrorObject;
        }
        newErrorObject[context.key] = message;
        return newErrorObject;
      }, {});
    return errorObject;
  };

  const clearErrors = (fieldName) => {
    const fieldsToUpdate = formStages[currentStageNumber - 1].fields;

    const fieldsWithErrorsCleared = fieldsToUpdate.map((field) => {
      if (fieldName) {
        if (fieldName === field.name) {
          return {
            ...field,
            error: null,
          };
        }
        return {
          ...field,
        };
      }
      return {
        ...field,
        error: null,
      };
    });
    updateStageFields(fieldsWithErrorsCleared);
  };

  const onSubmitTypeForm = () => {
    setCurrentStageNumber(currentStageNumber + 1);
    onStageChanged(currentStageNumber);
    localStorage.setItem(currentFormStage.benefitSelectionFormId, true);
  };

  const onCallToActionClicked = () => {
    clearErrors();
    if (submissionSuccess) {
      onFormComplete();
      return;
    }
    const hasTypeFormFilled = !!localStorage.getItem(
      currentFormStage.benefitSelectionFormId,
    );
    if (currentFormStage.ctaComponent && !hasTypeFormFilled) {
      localStorage.setItem(currentFormStage.benefitSelectionFormId, true);
      return;
    }
    const validationErrors = getValidationErrors();
    if (validationErrors) {
      const fieldsToUpdate = formStages[currentStageNumber - 1].fields;

      const fieldsWithErrors = fieldsToUpdate.map((field) => {
        if (validationErrors[field.name]) {
          return {
            ...field,
            error: validationErrors[field.name],
          };
        }
        if (field.type === 'address' || field.type === 'locationOfWork') {
          return {
            ...field,
            error: validationErrors.address,
          };
        }
        if (!validationErrors[field.name] && field.error) {
          return {
            ...field,
            error: null,
          };
        }
        return field;
      });
      updateStageFields(fieldsWithErrors);
      const errorKeys = Object.keys(validationErrors);
      if (errorKeys.length > 0) {
        const firstFieldInError = document?.getElementById(`${errorKeys[0]}`);
        firstFieldInError?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
      }
    }

    if (!validationErrors && !onLastStage) {
      setCurrentStageNumber(currentStageNumber + 1);
      onStageChanged(currentStageNumber);
    }
    if (!validationErrors && onLastStage) {
      if (checkFileUpload()) {
        uploadDocument(fileInfo);
      }
      if (hasTypeFormFilled) {
        localStorage.removeItem(currentFormStage.benefitSelectionFormId);
      }

      setOnSubmit(true);
    }
  };

  const currentFormStage = formStages.find(
    ({ stage }) => stage === currentStageNumber,
  );

  const getCTAText = () => {
    if (submissionSuccess && customSuccessCTAText) {
      return customSuccessCTAText;
    }
    if (onLastStage) {
      return 'Submit';
    }

    return 'Next';
  };

  const ctaColor = onLastStage ? 'primary' : 'secondary';

  const submitButtonProps = {
    onClick: onCallToActionClicked,
    disabled:
      (checkFileUpload() && documentUploading) ||
      Boolean(loading) ||
      disableCTA,
    children: getCTAText(),
    palette: ctaColor,
  };

  const hasTypeFormFilled = !!localStorage.getItem(
    currentFormStage.benefitSelectionFormId,
  );

  const typeFormButtonProps = {
    ...submitButtonProps,
    onClick: onSubmitTypeForm,
  };

  const buttonProps = hasTypeFormFilled
    ? typeFormButtonProps
    : submitButtonProps;

  const isCustomCTA = !!currentFormStage.ctaComponent && !hasTypeFormFilled;

  const FormCTA = () => {
    if (isCustomCTA) {
      return (
        <currentFormStage.ctaComponent
          text={currentFormStage.ctaText || ''}
          onSubmit={onCallToActionClicked}
          testId="staged-form-submit"
        />
      );
    }

    return (
      <StyledRightAlignButton testId="staged-form-submit" {...buttonProps} />
    );
  };

  const FormMobilCTA = () => {
    if (isCustomCTA) {
      return (
        <currentFormStage.ctaComponent
          text={currentFormStage.ctaText || ''}
          onSubmit={onCallToActionClicked}
          testId="staged-form-submit-mobile"
        />
      );
    }

    return <MobileButton testId="staged-form-submit-mobile" {...buttonProps} />;
  };

  return (
    <StyledStagedForm
      ref={formRef}
      data-testid="staged-form-container"
      noMargin
    >
      <StyledStagedFormWrapper>
        {submissionSuccess && formConfig.SuccessScreen && (
          <formConfig.SuccessScreen data={successScreenPayload} />
        )}

        {!submissionSuccess && (
          <>
            <StyledFormContainer>
              <StyledStagedFormHeader>
                <Typography
                  as="h4"
                  size="24"
                  weight="medium"
                  ellipsis="false"
                  hideParagraphSpacing="true"
                >
                  {stages[currentStageNumber - 1]?.name}
                </Typography>
                {formStages.length > 1 && (
                  <Typography
                    as="p"
                    hideParagraphSpacing
                    size="16"
                    weight="regular"
                  >
                    Step {currentStageNumber}/{formStages.length}
                  </Typography>
                )}
              </StyledStagedFormHeader>
              {loading && <Loading />}
              {!loading && (
                <StyledStagedFormPage>
                  {currentFormStage.fields.length > 0 && (
                    <StyledStagedFormRequiredFields>
                      * required field
                    </StyledStagedFormRequiredFields>
                  )}
                  {stages[currentStageNumber - 1]?.notifications && (
                    <Box my="32">
                      <Notification
                        id={`stage-${currentStageNumber}-notice`}
                        title={stages[currentStageNumber - 1].notifications}
                        intent="info"
                        type="banner"
                      />
                    </Box>
                  )}
                  {isHRISIntegrationActive && (
                    <HRISFormIntegrationContextProvider
                      onFillForm={fillIntegrationData}
                      onClearFilledData={clearIntegrationData}
                    >
                      <FormHRISIntegrationSection
                        visible={currentStageNumber === 1}
                      />
                    </HRISFormIntegrationContextProvider>
                  )}
                  {currentFormStage.pageComponent ? (
                    <currentFormStage.pageComponent countryLabel={country} />
                  ) : (
                    <FormPage
                      country={selectedCountry}
                      editable={!documentUploading}
                      onFieldFocus={clearErrors}
                      onFormChanged={onFormChanged}
                      formValues={formValues}
                      formFields={currentFormStage.fields.filter((field) => {
                        if (field.hidden) {
                          return false;
                        }
                        if (field.condition) {
                          return arePreconditionsMet(
                            field.condition,
                            formValues,
                          );
                        }
                        return true;
                      })}
                    />
                  )}
                </StyledStagedFormPage>
              )}
            </StyledFormContainer>
          </>
        )}
      </StyledStagedFormWrapper>
      <Viewport devices={['laptop', 'desktop', 'highRes']}>
        <Inline justify="space-between" mt="16" gap="16">
          {currentStageNumber > 1 && !submissionSuccess && (
            <Button
              tabIndex="0"
              onClick={onBackClicked}
              palette="secondary"
              variant="outlined"
            >
              Back
            </Button>
          )}
          {currentStageNumber === 1 && !submissionSuccess && onExitClick && (
            <Button
              tabIndex="0"
              onClick={onExitClick}
              palette="secondary"
              variant="outlined"
            >
              Exit
            </Button>
          )}
          <FormCTA />
        </Inline>
      </Viewport>
      <Viewport devices={['phone', 'tablet']}>
        <FormMobilCTA />
      </Viewport>
    </StyledStagedForm>
  );
};

const mapStateToProps = (state) => ({
  documentUploading: getDocumentUploadingSelector(state),
  fileInfo: getDocumentFileInfoSelector(state),
  fileUploaded: getDocumentUploadedSelector(state),
  uploadedDocumentLocation: getUploadedDocumentLocation(state),
});

const mapDispatchToProps = {
  uploadDocument: uploadDocumentAction,
};

StagedForm.propTypes = {
  formConfig: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onFormSubmit: PropTypes.func,
  submissionSuccess: PropTypes.bool,
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onStageChanged: PropTypes.func,
  onFormComplete: PropTypes.func,
  documentUploading: PropTypes.bool,
  disableCTA: PropTypes.bool,
  country: PropTypes.string,
  uploadDocument: PropTypes.func,
  fileInfo: PropTypes.object,
  fileUploaded: PropTypes.bool,
  uploadedDocumentLocation: PropTypes.string,
  withHRISIntegration: PropTypes.bool,
  successScreenPayload: PropTypes.object,
  onExitClick: PropTypes.func,
  onFormChangeHandler: PropTypes.func,
  customSuccessCTAText: PropTypes.string,
};

StagedForm.defaultProps = {
  onFormSubmit: () => {},
  submissionSuccess: false,
  loading: false,
  onStageChanged: () => {},
  onFormComplete: () => {},
  uploadDocument: () => {},
  fileInfo: {},
  fileUploaded: false,
  documentUploading: false,
  disableCTA: false,
  country: '',
  withHRISIntegration: false,
  customSuccessCTAText: 'Got it',
};

export { StagedForm as UnconnectedStagedForm };
export default connect(mapStateToProps, mapDispatchToProps)(StagedForm);
