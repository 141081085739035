import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import {
  AnnualBaseSalary,
  SalaryHistoryEntry,
} from 'common/types/salaryHistory';
import { DateTime } from 'luxon';
import Skeleton from 'react-loading-skeleton';

export enum ColumnHeaders {
  employeeName = 'Employee name',
  reason = 'Reason',
  oldAnnualGrossAmount = 'Old annual gross amount',
  newAnnualGrossAmount = 'New annual gross amount',
  effectiveDate = 'Effective date',
  requestedAt = 'Request date',
}

const columnHelper = createColumnHelper<SalaryHistoryEntry>();

export const Columns: ColumnDef<SalaryHistoryEntry, any>[] = [
  columnHelper.accessor('employeeName', {
    cell: (info) => info?.getValue() || '',
    header: () => ColumnHeaders.employeeName,
    size: 200,
    enableGlobalFilter: true,
  }),
  columnHelper.accessor('reason', {
    cell: (info) => info?.getValue() || '',
    header: () => ColumnHeaders.reason,
    size: 100,
  }),
  columnHelper.accessor('previousSalary', {
    cell: (info) => {
      const { currency = '', amount }: AnnualBaseSalary =
        info?.getValue() || {};
      return `${currency?.toUpperCase()} ${amount?.toFixed(2) ?? ''}`;
    },
    header: () => ColumnHeaders.oldAnnualGrossAmount,
    size: 100,
  }),
  columnHelper.accessor('newSalary', {
    cell: (info) => {
      const { currency = '', amount }: AnnualBaseSalary =
        info?.getValue() || {};
      return `${currency?.toUpperCase()} ${amount?.toFixed(2) ?? ''}`;
    },
    header: () => ColumnHeaders.newAnnualGrossAmount,
    size: 100,
  }),
  columnHelper.accessor('effectiveDate', {
    cell: (info) =>
      info?.getValue()
        ? DateTime.fromISO(info?.getValue() as string).toFormat('MMM dd, yyyy')
        : '',
    sortingFn: 'datetime',
    header: () => ColumnHeaders.effectiveDate,
    size: 100,
  }),
  columnHelper.accessor('requestedAt', {
    cell: (info) =>
      info?.getValue()
        ? DateTime.fromISO(info?.getValue() as string).toFormat('MMM dd, yyyy')
        : '',
    header: () => ColumnHeaders.requestedAt,
    size: 100,
    sortingFn: 'datetime',
  }),
];

export const LoadingColumns: ColumnDef<any, any>[] = [
  columnHelper.display({
    id: 'employeeName',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.employeeName,
    size: 200,
  }),
  columnHelper.display({
    id: 'reason',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.reason,
    size: 100,
  }),
  columnHelper.display({
    id: 'previousSalary',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.oldAnnualGrossAmount,
    size: 100,
  }),
  columnHelper.display({
    id: 'newSalary',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.newAnnualGrossAmount,
    size: 100,
  }),
  columnHelper.display({
    id: 'effectiveDate',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.effectiveDate,
    size: 100,
  }),
  columnHelper.display({
    id: 'requestedAt',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.requestedAt,
    size: 100,
  }),
];
