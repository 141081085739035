import { DateTime } from 'luxon';

import { formatDateWithTimeAndTimezone } from '@omnipresentgroup/design-system';

export const getNextPayrollCutOffDate = (currentDate?: DateTime) => {
  const now = currentDate || DateTime.utc();

  let payrollDate;

  if (now.month === 11 && now.day > 6) {
    // Special case for November after the 6th
    payrollDate = now.set({
      month: 11,
      day: 30,
      hour: 23,
      minute: 59,
      second: 0,
      millisecond: 0,
    });
  } else if (now.month === 12) {
    // Special case for December
    payrollDate = now.set({
      year: now.year + 1,
      month: 1,
      day: 6,
      hour: 23,
      minute: 59,
      second: 0,
      millisecond: 0,
    });
  } else {
    // Regular case
    const cutoffThisMonth = now.set({
      day: 6,
      hour: 23,
      minute: 59,
      second: 0,
      millisecond: 0,
    });
    payrollDate =
      now <= cutoffThisMonth
        ? cutoffThisMonth
        : cutoffThisMonth.plus({ months: 1 });
  }

  return formatDateWithTimeAndTimezone(payrollDate.toLocal().toISO() || '');
};
