import { client } from 'api';

import {
  CompanyTimeOffLogsResponse,
  CreateTimeOffLogsPayload,
  EmployeeTimeOffLogsResponse,
  TimeOffLog,
} from '../types';

export const TIME_OFF_LOGS_BASE_API_ENDPOINT = '/time-off';

export const composeTimeOffLogsEndpointByScope = (
  scope: 'employee' | 'company',
  id: string,
) => `${TIME_OFF_LOGS_BASE_API_ENDPOINT}/by-${scope}-id/${id}/requests`;

export const getEmployeeTimeOffLogsRequest = (employeeId: string) =>
  client.get<EmployeeTimeOffLogsResponse>(
    composeTimeOffLogsEndpointByScope('employee', employeeId),
  );

export const getEmployeeTimeOffLogsByYearMonthRequest = (
  employeeId: string,
  year: number,
  month: number,
) =>
  client.get<TimeOffLog[]>(
    `${TIME_OFF_LOGS_BASE_API_ENDPOINT}/by-employee-id/${employeeId}/requests?year=${year}&month=${month}`,
  );

export const createEmployeeTimeOffLogRequest = (
  newTimeOff: CreateTimeOffLogsPayload,
) => client.post<TimeOffLog>(TIME_OFF_LOGS_BASE_API_ENDPOINT, newTimeOff);

export const getCompanyTimeOffLogsRequest = (companyId: string) =>
  client.get<CompanyTimeOffLogsResponse>(
    composeTimeOffLogsEndpointByScope('company', companyId),
  );

export const deleteEmployeeTimeOffLogRequest = (timeOffLogid: string) =>
  client.delete<void>(
    `${TIME_OFF_LOGS_BASE_API_ENDPOINT}/requests/${timeOffLogid}`,
  );
