import { isTimeOffIntegrationEnabled } from 'utils/featureFlags';
import { reduceInputField } from 'utils/transformer-utils/input.transformer';
import {
  getUserMainRole,
  roleTypeToUserFriendlyRoleTypeName,
} from 'utils/user';

export const getTimeOffFeatureFlagField = (companySettings) => ({
  name: 'isTimeOffEnabled',
  type: 'toggle',
  label: 'Time Off Feature',
  value: companySettings?.isTimeOffEnabled,
  editable: true,
  displayValue: companySettings?.isTimeOffEnabled ? 'On' : 'Off',
});

export const disableContractAutomationField = (companySettings) => ({
  name: 'disableContractAutomation',
  type: 'toggle',
  label: 'Contract Automation',
  //disableContractAutomation negative flag value reversed for for UI display
  value: !companySettings?.disableContractAutomation,
  editable: true,
  displayValue: !companySettings?.disableContractAutomation ? 'On' : 'Off',
});

const defaultIntegrations = {
  hris: [],
};

export const transformCompanyFromApi = ({
  id,
  /** FIXME
   * company does not have userId
   */
  userId,
  adminNotes,
  name: companyName,
  invoicingEmail,
  identifiers,
  primaryContact,
  onboardingStage,
  countryOfRegistration,
  noOfEmployees: numberOfEmployees,
  registeredAddress,
  managers,
  needsManagerReview,
  externalId,
  settings,
  integrations,
  isVATExempt,
  fxConversionPercent,
  benefitsPercentage,
  salaryPrepayment,
  latePayment,
  enabledProducts,
}) => {
  const { vatNumber, companyNumber } = identifiers || {};
  const contactName = primaryContact?.name || '';
  const contactPhoneNumber = primaryContact?.phoneNumber || '';
  const companyIntegrations = integrations || defaultIntegrations;
  return {
    id,
    userId,
    companyName,
    onboardingStage,
    countryOfRegistration,
    managers,
    needsManagerReview,
    numberOfEmployees,
    vatNumber,
    companyNumber,
    contactName,
    contactPhoneNumber,
    companyDetails: [
      {
        name: 'companyName',
        label: 'Full legal name',
        value: companyName,
        type: 'string',
        editable: true,
        required: true,
      },
      {
        name: 'countryOfRegistration',
        label: 'Country of registration',
        value: countryOfRegistration,
        type: 'country',
        editable: true,
        required: true,
        disabled: true,
      },
      {
        name: 'registeredAddress',
        label: 'Registered address',
        value: registeredAddress,
        type: 'address',
        editable: true,
        required: true,
      },
      {
        name: 'vatNumber',
        label: 'Company VAT number (if applicable)',
        value: vatNumber,
        type: 'string',
        editable: true,
      },
      {
        name: 'companyNumber',
        label: 'Company registration number',
        value: companyNumber,
        type: 'string',
        editable: true,
      },
      {
        name: 'contactName',
        label: 'Primary contact name',
        value: contactName,
        type: 'string',
        editable: true,
        required: true,
      },
      {
        name: 'contactPhoneNumber',
        label: 'Primary contact phone',
        value: contactPhoneNumber,
        type: 'string',
        editable: true,
        required: true,
      },
      {
        name: 'invoicingEmail',
        label: 'Invoice email',
        type: 'email',
        value: invoicingEmail,
        editable: true,
        required: true,
      },
    ],
    administrativeInformation: [
      {
        name: 'adminNotes',
        label: 'Notes',
        value: adminNotes,
        type: 'text',
        editable: true,
      },
      {
        name: 'externalId',
        label: 'External ID',
        value: externalId,
        type: 'string',
      },
      isTimeOffIntegrationEnabled() && getTimeOffFeatureFlagField(settings),
      disableContractAutomationField(settings),
      {
        name: 'isVATExempt',
        label: 'VAT Exempt',
        value: isVATExempt,
        type: 'toggle',
        editable: true,
        displayValue: isVATExempt ? 'Yes' : 'No',
      },
      {
        name: 'fxConversionPercent',
        label: 'FX Conversion Percent',
        value: fxConversionPercent,
        editable: true,
        type: 'number',
      },
      {
        name: 'benefitsPercentage',
        label: 'Benefit Fee Percentage',
        value: benefitsPercentage || 0,
        displayValue: benefitsPercentage || '-',
        editable: true,
        disabled: true,
        type: 'number',
      },
      {
        name: 'paymentTerms',
        label: 'Payment terms',
        value: salaryPrepayment?.terms || 0,
        displayValue: salaryPrepayment?.terms || '-',
        editable: true,
        disabled: true,
        type: 'number',
      },
      {
        name: 'salaryPrepaymentMultiplier',
        label: 'Salary prepayment multiplier',
        value: salaryPrepayment?.multiplier || 0,
        displayValue: salaryPrepayment?.multiplier || '-',
        editable: true,
        disabled: true,
        type: 'number',
      },
    ].filter(Boolean),
    settings,
    integrations: companyIntegrations,
    isVATExempt,
    fxConversionPercent,
    salaryPrepayment,
    latePayment,
    enabledProducts,
  };
};

export const transformCompanyToAPI = (companyToTransform) => {
  let transformedFields = {};
  companyToTransform.forEach((stage) => {
    const stageObject = stage.fields.reduce(reduceInputField, {});
    transformedFields = {
      ...transformedFields,
      fxConversionPercent: stageObject.fxConversionPercent?.toString(),
      name: stageObject.name,
      managers: [
        {
          firstName: stageObject.managerFirstName,
          lastName: stageObject.managerLastName,
          email: stageObject.managerEmail,
        },
      ],
      ...(stageObject.isTimeOffEnabled
        ? {
            settings: {
              ...stageObject.settings,
              isTimeOffEnabled: stageObject.isTimeOffEnabled,
            },
          }
        : {}),
      ...(stageObject.disableContractAutomation
        ? {
            settings: {
              ...stageObject.settings,
              disableContractAutomation: stageObject.disableContractAutomation,
            },
          }
        : {}),
    };
  });
  return transformedFields;
};

export const transformCompanyForTable = ({
  id,
  name,
  onboardingStage,
  countryOfRegistration,
  noOfEmployees,
  managers,
}) => ({
  id,
  companyName: name,
  countryOfRegistration,
  onboardingStage,
  managers,
  noOfEmployeesTable: noOfEmployees.toString(),
  noOfEmployeesCard: `${noOfEmployees || 0} employees`,
});

export const transformCompanyForUpdate = (companyToTransform) => {
  const {
    companyName,
    vatNumber,
    companyNumber,
    contactName,
    contactPhoneNumber,
    isTimeOffEnabled,
    disableContractAutomation,
    fxConversionPercent,
    terms,
    multiplier,
    ...rest
  } = companyToTransform;

  return Object.assign(
    rest,
    typeof isTimeOffEnabled === 'boolean' && {
      settings: {
        ...companyToTransform.settings,
        isTimeOffEnabled: isTimeOffEnabled,
      },
    },
    typeof disableContractAutomation === 'boolean' && {
      settings: {
        ...companyToTransform.settings,
        //disableContractAutomation edited new value from UI component also reversed for update
        disableContractAutomation: !disableContractAutomation,
      },
    },

    companyName && { name: companyName },
    (vatNumber || companyNumber) && {
      identifiers: {
        vatNumber,
        companyNumber,
      },
    },
    (contactName || contactPhoneNumber) && {
      primaryContact: {
        name: contactName,
        phoneNumber: contactPhoneNumber,
      },
    },
    fxConversionPercent?.toString() && {
      fxConversionPercent: fxConversionPercent?.toString(),
    },

    terms && {
      paymentTerms: {
        ...paymentTerms,
        terms: terms,
      },
    },

    multiplier && {
      paymentTerms: {
        ...paymentTerms,
        multiplier: multiplier,
      },
    },
  );
};

export const transformCompanyManager = ({
  userId,
  firstName,
  lastName,
  email,
  roles,
}) => ({
  id: userId,
  name: `${firstName} ${lastName}`,
  email,
  role: roleTypeToUserFriendlyRoleTypeName(getUserMainRole(roles).type),
});

export const transformCompanyManagerToApi = (managerDetails) =>
  managerDetails[0].fields.reduce((result, field) => {
    return reduceInputField(result, field, false);
  }, {});

export const transformManagerDetailsForEditManagerPage = (manager) => {
  return manager?.userDetails
    .filter((detail) => detail.editable)
    .filter(
      ({ name: fieldName }) =>
        fieldName === 'firstName' || fieldName === 'lastName',
    );
};
