import { financialDocsClient } from 'api';
import { AxiosResponse } from 'axios';
import queryString from 'query-string';
import { BillingCurrency } from 'utils/currencies';
import {
  InvoiceReportsBalancesStatusResponse,
  InvoiceStatus,
  InvoiceStatusV2,
  NetSuiteInvoiceType,
  NetSuiteInvoiceTypeV2,
} from 'utils/queries/invoiceReports/types';

import { removeFalsy } from '../../utils/objects';
import { PayrollReportsPeriodListApiResponse } from '../types';

export const PAYROLL_REPORTS = '/payroll-reports';

export const getPayrollReportsPeriodList = async (companyId: string) => {
  return financialDocsClient.get<PayrollReportsPeriodListApiResponse>(
    `${PAYROLL_REPORTS}/companies/${companyId}/gross-to-net`,
  );
};

export const downloadPayrollReportGrossToNet = async ({
  companyId,
  payrollPeriod,
}: {
  companyId: string;
  payrollPeriod: string;
}) => {
  return financialDocsClient.get(
    `${PAYROLL_REPORTS}/companies/${companyId}/gross-to-net/${payrollPeriod}`,
  );
};

export type DownloadTransactionBalancesRequest = {
  companyId: string;
  dateFrom: string;
  dateTo: string;
  currency: BillingCurrency | null;
};

export const downloadTransactionBalances = async ({
  companyId,
  dateFrom,
  dateTo,
  currency,
}: DownloadTransactionBalancesRequest) => {
  return financialDocsClient.get(
    `/invoice-reports/companies/${companyId}/transaction-balances/download`,
    {
      params: removeFalsy({
        currency,
        from_date: dateFrom,
        to_date: dateTo,
      }),
    },
  );
};

export const getFinancialDocsInvoiceReportsCountRequest = (
  companyId: string,
  params: { types?: NetSuiteInvoiceType[]; status?: InvoiceStatus },
) => {
  return financialDocsClient.get(
    `invoice-reports/companies/${companyId}/count/`,
    {
      params,
    },
  );
};

export const getFinancialDocsInvoiceReportsCountRequestV2 = (
  companyId: string,
  params: { types?: NetSuiteInvoiceTypeV2[]; status?: InvoiceStatusV2 },
) => {
  return financialDocsClient.get(
    `v2/invoice-reports/companies/${companyId}/count/`,
    {
      params,
      paramsSerializer: (queryParams) =>
        queryString.stringify(queryParams, { arrayFormat: 'none' }),
    },
  );
};

export const getFinancialDocsInvoiceBalancesRequest = (
  companyId: string,
  params: { currencies?: BillingCurrency[] },
) => {
  return financialDocsClient.get(
    `invoice-reports/companies/${companyId}/balances`,
    {
      params,
    },
  );
};

export const getFinancialDocsInvoiceBalancesStatusRequest = (
  companyId: string,
): Promise<AxiosResponse<InvoiceReportsBalancesStatusResponse>> => {
  return financialDocsClient.get<InvoiceReportsBalancesStatusResponse>(
    `invoice-reports/companies/${companyId}/balances/status`,
  );
};
