import { useEffect, useState } from 'react';
import { Route, useLocation, useRouteMatch } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { History } from 'history';
import { useTrack } from 'utils/mixpanel/tracker';

import { IS_DEV_ENV } from './common/constants';

export const sentryInit = (history: History) => {
  if (!process.env.VITE_SENTRY_DSN || IS_DEV_ENV) {
    return;
  }

  Sentry.init({
    dsn: process.env.VITE_SENTRY_DSN,
    tunnel: process.env.VITE_OMNIPLATFORM_API_ENDPOINT + '/sentry',
    environment: process.env.VITE_STAGE,
    release: process.env.VITE_COMMIT_SHA,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    ignoreErrors: ['Non-Error promise rejection captured'],
    tracesSampleRate: 1.0,
  });
};

const WithSentryRouting = Sentry.withSentryRouting(Route);

export type SentryRouteProps = {
  component?: any;
  doNotTrack?: boolean;
  exact?: boolean;
  path: string;
  children?: React.ReactNode;
};

export const SentryRoute = (props: SentryRouteProps) => {
  const { doNotTrack, path } = props;
  const location = useLocation();
  const match = useRouteMatch({ path: path, exact: true, strict: true });
  const [route, setRoute] = useState<string>();
  const track = useTrack();

  useEffect(() => {
    if (match) {
      setRoute(`${match.path}${location.hash}`);
    }
  }, [location, match, route]);

  useEffect(() => {
    if (route && !doNotTrack) {
      track('Page Viewed', { page: route });
    }
  }, [route, doNotTrack, track]);

  return <WithSentryRouting {...props} />;
};
