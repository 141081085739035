import { client } from 'api';
import { SalaryHistoryEntry } from 'common/types/salaryHistory';
import { SALARY_HISTORY } from 'paths';

export type QueryParams = {
  pageSize?: number;
  nextPageKey?: string;
};

export type GetSalaryHistoryResponse = {
  salaryHistory: SalaryHistoryEntry[];
};

export const getSalaryHistory = ({
  companyId,
  params,
}: {
  companyId: string;
  params?: QueryParams;
}) =>
  client.get<GetSalaryHistoryResponse>(
    `${SALARY_HISTORY}/company/${companyId}`,
    {
      params,
    },
  );
