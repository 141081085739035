import appHistory from 'app/appHistory';
import axios, { AxiosInstance } from 'axios';

import { is401UnauthorizedError } from './utils/error.helper';

export const client = axios.create({
  baseURL: process.env.VITE_OMNIPLATFORM_API_ENDPOINT,
});

export const clientKnowledgeClient = axios.create({
  baseURL: process.env.VITE_KNOWLEDGE_API_ENDPOINT,
});

export const clientInvoicesClient = axios.create({
  baseURL: process.env.VITE_OMNICLIENTINVOICE_API_ENDPOINT,
});

export const contractorsClient = axios.create({
  baseURL: process.env.VITE_CONTRACTORS_API_ENDPOINT,
});

export const compensationClient = axios.create({
  baseURL: process.env.VITE_COMPENSATION_API_ENDPOINT,
});

export const omniCreateClient = axios.create({
  baseURL: process.env.VITE_OMNICREATE_API_ENDPOINT,
});

export const payrollTaxonomyClient = axios.create({
  baseURL: process.env.VITE_OMNIPLATFORM_PAYROLL_TAXONOMY,
});

export const payrollServiceClient = axios.create({
  baseURL: process.env.VITE_OMNIPLATFORM_PAYROLL_SERVICE,
});

export const onboardingServiceClient = axios.create({
  baseURL: process.env.VITE_ONBOARDING_API_ENDPOINT,
});

export const financialDocsClient = axios.create({
  baseURL: process.env.VITE_FINANCIAL_DOCS_API_ENDPOINT,
});

export const cutoffReportsClient = axios.create({
  baseURL: process.env.VITE_CUTOFF_REPORTS_API_ENDPOINT,
});

const createInterceptor = (axiosClient: AxiosInstance) => {
  axiosClient.interceptors.response.use(
    (response) => response,
    (error) => {
      if (is401UnauthorizedError(error)) {
        appHistory.push('/logout');
      }

      return Promise.reject(error);
    },
  );
};

createInterceptor(client);
createInterceptor(financialDocsClient);
createInterceptor(clientInvoicesClient);
createInterceptor(contractorsClient);
createInterceptor(compensationClient);
createInterceptor(omniCreateClient);
createInterceptor(payrollTaxonomyClient);
createInterceptor(payrollServiceClient);
createInterceptor(onboardingServiceClient);
createInterceptor(cutoffReportsClient);

export function setAuthHeader(token: string) {
  client.defaults.headers.common.Authorization = `Bearer ${token}`;
  financialDocsClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  clientInvoicesClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  contractorsClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  compensationClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  omniCreateClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  payrollTaxonomyClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  payrollServiceClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  onboardingServiceClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  cutoffReportsClient.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export const awsClient = axios.create({
  baseURL: process.env.VITE_OMNIPLATFORM_API_ENDPOINT,
});
