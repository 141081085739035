import { getEmployeeTimeOffLogsRequest } from 'common/api/timeOffLogs.api';
import { FIVE_MINUTES, ONE_MINUTE } from 'common/constants';
import { useQuery, UseQueryOptions } from 'react-query';
import { useSelectUserRoles } from 'utils/hooks';
import { getDataFromResponse } from 'utils/react-query-utils';
import { sortTimeOffLogsByDate } from 'utils/timeOffLogs.helper';

import { timeOffLogsKeys } from '../keys';

export const useEmployeeTimeOffQuery = (
  employeeId: string,
  options?: Pick<UseQueryOptions, 'staleTime'>,
) => {
  const { isEmployee, isLSP } = useSelectUserRoles();

  // Determine the staleTime
  const determineStaleTime = () => {
    if (options?.staleTime) {
      return options.staleTime;
    }
    return isEmployee ? FIVE_MINUTES : ONE_MINUTE;
  };

  return useQuery(
    timeOffLogsKeys.singleEmployee(employeeId),
    () => getEmployeeTimeOffLogsRequest(employeeId).then(getDataFromResponse),
    {
      staleTime: determineStaleTime(),
      select: sortTimeOffLogsByDate,
      enabled: !isLSP && Boolean(employeeId),
    },
  );
};
